@import "../../styles/base/variables";

.quoteWapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.quote {
  flex: 1;
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    z-index: -1;
    position: absolute;
    content: "";
    display: block; //for IE

    width: 100%;
  }

  .center{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 36px;
    color: #fff;
    //text-transform: uppercase;
    display: inline;
  }

  .title-text {
    color: $app-color-primary;
  }

  .button-primary {
    margin-top: 49px;
    margin-bottom: 31px;
    background-color: $app-color-primary;
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    width: 320px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    padding-top: 14px;
    border: none;
  }

  .button-secondary {
    margin-top: 29px;
    margin-bottom: 11px;
    background-color: $app-color-secondary;
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    width: 320px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    padding-top: 14px;
    border: none;
  }

}