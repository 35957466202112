@import "../../styles/base/variables";

.first-footer{
    padding-bottom: 16px;
}

.footer-widget{
    line-height: 1.7em;

    .right {
        text-align: right;
    }

    .left {
        text-align: left;
    }

    .title {
        font-size: 14pt;
        // color: #c4112f!important;
        color: #0dab8e !important;
    }

    a#phone-number{
        font-weight: 700;
        // color: #c4112f!important;
        color: #0dab8e !important;
        // border: 2px solid #f58c1f;
        border: 2px solid #0dab8e;
        padding: 10px 30px;
        border-radius: 30px;
        font-size: 20px;
        display: block;
        float: right;
        min-width: 210px;
        max-width: 210px;
        margin-left: 30px;
    }

    a#email {
        font-weight: 700;
        // border: 2px solid #f58c1f;
        border: 2px solid #0dab8e;
        padding: 10px;
        border-radius: 30px;
        font-size: 18px;
        // background: #f58c1f;
        background: #0dab8e;
        display: block;
        color: white;
        min-width: 210px;
        max-width: 210px;
        margin-left: 30px;
    }

    .textwidget {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: 0 0;
        font-size: 100%;
        vertical-align: baseline;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-align: center;
    }

    .custom-html-tile {
        text-align: left;
        font-weight: normal;
        font-size: 14pt;
        color: $app-color-grey;
    }
}

.footer-last{
    padding-top: 10px;
    min-height: 40px;
    color: $app-color-grey;
    font-size: 12pt;
    text-decoration: none;

    .footer-info {
        float: left;
        margin-left: 0px;
        padding-top: 4px;
        padding-bottom: 4px;
        color: $app-color-grey;
        text-align: left;
    }

    .footer-info a{
        color: $app-color-grey4;
    }
}

.footer-bottom{
    background-color: #c4112f;

    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 58px;
    color: #fff;
    font-size: 2em;
    text-decoration: none;

    .didonerightLeft{
        background-position-x: left;
        background-position-y: center;
        background-image: url(../../assets/footer/didoneright.png);
        background-size: auto 24px;
        background-repeat: no-repeat;
        min-width: 300px;
        min-height: 52px;
    }
}

.logoStripWrapper {
    min-height: 50px;
    background-image: url(https://www.diservices.com/wp-content/uploads/2019/11/logo-strip.png);
    background-size: contain;
    background-repeat: no-repeat;
}