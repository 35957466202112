@import "../../styles/base/variables";

.footer-short-widget{
    line-height: 1.7em;

    .right {
        text-align: right;
    }

    .left {
        text-align: left;
    }

    .title {
        font-size: 18px;
        color: #c4112f!important;
    }

    a#phone-number{
        font-weight: 700;
        color: #c4112f!important;
        border: 2px solid #f58c1f;
        padding: 10px 30px;
        border-radius: 30px;
        font-size: 20px;
        display: block;
        float: right;
    }

    a#email {
        font-weight: 700;
        border: 2px solid #f58c1f;
        padding: 10px;
        border-radius: 30px;
        font-size: 18px;
        background: #f58c1f;
        display: block;
        color: white;
    }

    .textwidget {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: 0 0;
        font-size: 100%;
        vertical-align: baseline;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-align: center;
    }

    .custom-html-tile {
        text-align: left;
    }
}

.footer-short-bottom{
    background-color: $app-color-primary;

    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 40px;
    color: #fff;
    font-size: 1.4em;
    text-decoration: none;

    .footer-short-info{
        float: left;
        margin-top: 16px;
        //padding-bottom: 5px;
        color: #fff;
        text-align: left;
    }

    .didoneright {
        background-position-y: center;
        background-position-x: right;
        background-image: url(../../assets/footer/didoneright.png);
        background-size: auto 24px;
        background-repeat: no-repeat;
        min-width: 160px;
        min-height: 52px;
    }

    .footer-short-info a{
        color: #fff;
    }
}

.logoStripWrapper {
    min-height: 50px;
    background-image: url(https://www.diservices.com/wp-content/uploads/2019/11/logo-strip.png);
    background-size: contain;
    background-repeat: no-repeat;
}