@import "../../../styles/base/variables";

.toggle-field {
  font-size: 1.1rem;
  position: relative;
  display: inline-block;
  z-index: 0;
  color: $app-color-grey8;

  label {
    font-size: 1.4rem;
    font-weight: 900;
    position: relative;
    z-index: 1;
    padding-right: 6rem;
    &:hover {
      cursor: pointer;
    }
    margin-bottom: 4rem;
  }

  &:before,
  &:after {
    display: inline-block;
    content: "";
    position: absolute;
    transition: 300ms ease all;
    z-index: -1;
  }

  &:before {
    width: 3.4rem;
    height: 1.4rem;
    border: 2px solid transparent;
    top: 0;
    left: auto;
    right: 0;
    border-radius: 2.5rem;
    background-color: $app-color-grey8;
  }

  &:after {
    width: 2rem;
    height: 2rem;
    background-color: $app-color-grey9;
    box-shadow: $box-shadow3;
    border-radius: 2.5rem;
    right: 1.5rem;
    left: auto;
    top: -0.34rem;
    color: $app-color-white;
  }

  &.active {
    color: $app-color-black;

    &:after {
      content: "";
      right: 0;
      left: auto;
      background-color: $app-color-red4;
    }

    &:before {
      background-color: $app-color-red4;
      border-color: transparent;
      opacity: 0.5;
    }
  }

  input {
    visibility: hidden;
    position: absolute;
  }
}
