@import "../../styles/base/variables";

.quotesent {
  //background-image: url(../../assets/login/login-dis-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;

  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    //background-color: rgba($color: #000000, $alpha: 0.65);
    z-index: -1;
    position: absolute;
    content: "";
    display: block; //for IE
    height: 100vh;
    width: 100%;
  }

  .center{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 16px;
    color: #fff;
    //text-transform: uppercase;
    display: inline;
  }

  .red-text {
    color: $app-color-red;
  }

  .button {
    margin-top: 49px;
    margin-bottom: 31px;
    background-color: $app-color-red4;
    color: #fff;
    font-size: 11px;
    width: 320px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    padding-top: 16px;
    border: none;
  }

  .buttonred {
    margin-top: 49px;
    margin-bottom: 31px;
    background-color: #0677BB;
    color: #fff;
    font-size: 11px;
    width: 320px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    padding-top: 16px;
    border: none;
  }
}