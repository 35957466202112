@import "../../styles/base/variables";

.primary {
  color: $app-color-primary;
}

.heading1 {
  font-size: 5.6em;
  font-weight: normal;
}

.logoPreview{
  max-height: 100px;
  max-width: 140px;
}

.logoPreviewContaner{
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
}

.proposal-details {

  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__container {
    max-width: 800px;
    width: 100%;
    margin: 0;
    margin-right: 3rem;
  }

  &__builder {
    padding-top: 10px;
    box-shadow: $box-shadow2;
    margin-bottom: 3rem;
    overflow: hidden;
    height: 100%;
    min-height: 100%;
  }
}

.center {
  text-align: center;
}