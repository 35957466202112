@import "../../styles/base/variables";

.client {
  &__form-wrap {
    padding: 2rem;

    ul {
      margin-bottom: 3rem;
      // width: 30rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__drop-input {
    width: 100%;
    margin-top: -0.7rem;
    border: none;
    background-color: transparent;
    font-weight: 300;
    outline: none;

    ul {
      font-size: 1.4rem;
    }

    input {
      font-size: 1.8rem;
      color:#000;
      font-weight: 300;
      background-color: transparent;

      &:focus {
        border-bottom: 0.2rem solid #47474f !important;
        background-color: transparent;
      }
    }
  }

  &__dob {
    input {
      font-size: 1.2rem;
    }
  }

  &__checkbox-group {
    margin-bottom: 3rem;
  }
}

.single-select-field select  {
  height: 28px;
}

.incomeRow{
  padding-top: 20px;
}

.emailNoteCustom {
  div ul {
    margin-bottom: 0px;
  }
}
.clientNote {
  font-size: 10px;
}

.clientNoteCustom {
  div {
    margin-bottom: 0px;
  }
}
