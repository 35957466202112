@import "../../../styles/base/variables";

.dob {
  color: #000;

  &__wrap {
    button {
      display: none;
    }
    label.MuiInputLabel-formControl {
      transform: translate(0, 1.5px) scale(1);
      font-size: 1.4rem;
    }

    .MuiInputLabel-asterisk {
      color: $app-color-red;
    }

    // .MuiFormLabel-root {
    //   color: #000;
    // }
    .MuiFormControl-root{
      display: block;
    }

    input {
      font-size: 1.8rem;
      padding: 4px 0 1.9px;
      min-height: 32px;
      font-weight: 300;
    }

    &__error {
      color: $app-color-red;

      label {
        color: $app-color-red;
      }
      input {
        color: $app-color-red !important;
      }
    }
  }
}
