.button {
    &__primary {
        background-color: $app-color-primary;
        border: none;
        min-width: 12.6rem;
        color: white;
        border-radius: 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 0.7rem 0;

        &:focus {
            outline: none;
        }
    }

    &__secondary {
        background-color: $app-color-secondary;
        border: none;
        min-width: 12.6rem;
        color: white;
        border-radius: 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 0.7rem 0;

        &:focus {
            outline: none;
        }
    }

    &__orange {
        background-color: $app-color-blue3;
        min-width: 12.6rem;
        color: white;
        border-radius: 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 0.7rem 0;

        &:focus {
            outline: none;
        }
    }

    &__white {
        background-color: transparent;
        min-width: 12.8rem;
        padding: 1rem 0.5rem;
        border-radius: 2rem;
        color: $app-color-grey;
        border: 1px solid $app-color-grey;


        &:focus {
            outline: none;
        }

        &:active {
            background-color: $app-color-red4;
            color: $app-color-white;
            border-color: $app-color-white;
        }

        &--mb {
            margin-bottom: 5rem;
        }

        &--orange-hover {
            &:hover,
            &:active,
            &:focus {
                background-color: $app-color-red4 !important;
                color: $app-color-white;
                border-color: $app-color-white;
            }
        }

        &--small {
            min-width: auto;
            padding: 0.2rem 2rem;
        }
    }

    &__transparent {
        background-color: transparent;
        border: 1px solid $app-color-grey;
        border-radius: 2rem;
        padding: 0.8rem 1.6rem 0.8rem 3rem;
        font-size: 1.1rem;
        line-height: 1.1;
        font-weight: 500;
        position: relative;

        &:active {
            background-color: $app-color-red4;
            color: $app-color-white;
            border-color: $app-color-white;
        }

        &:before {
            content: "";
            font-family: $app-font-icons;
            font-size: 18px;
            position: absolute;
            top: 0.1rem;
            left: 0.6rem;
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 50%;
          }

          &--download {

            &:before {
              background-image: url("../assets/dashboard/save-icon.png");
              background-repeat: no-repeat;
              background-position: center center;
            }
          }

          &--small {
              padding: 1rem 1rem;
          }
    }

    &__grey {
        background-color: $app-color-grey;
        font-size: 1.1rem;
        line-height: 1.1;
        font-weight: 500;
        color: $app-color-white;
        border-radius: 2rem;
        padding: 0.8rem 3.6rem;

        &:active {
            background-color: transparent;
            color: $app-color-black;
            border-color: $app-color-black;
        }

        &:hover {
            background-color: $app-color-red4 !important;
            color: $app-color-white;
            border-color: $app-color-white;
        }
    }

    &__medication-link {
        background-color: transparent;
        color: $app-color-red4;
        border: none;
        font-size: 1.1rem;
        letter-spacing: 0.39px;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    &__small {
        padding: 1rem 2rem;
        background-color: transparent;
    }
}

button {
    outline: none !important;
}