@import "../../styles/base/variables";

.age {
  &__text-field-disabled {
    ul {
      margin-bottom: 2.4rem !important;
      padding: 0;
      > li {
        list-style: none;
      }
    }

    span {
      font-size: 1.4rem;
      color: $app-color-grey2;
    }
  }

  &__label-wrap {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px $app-color-grey2;
    color: $app-color-grey2;
    outline: none;
    label {
      display: block;
      min-height: 2rem;
      font-size: 1.8rem;
    }
  }
}
