@import "../../../styles/base/variables";

.number-field-light {
  &__tooltipIcon{
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .required {
    color: $app-color-red;
  }
  
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 10px;
    color: #fff;
  }

  .field-input {
    width: 320px;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px#ffffff;
    color: #fff;
    font-size: 18px;
    outline: none;
  }
}

.number-field-dark {
  .required {
    color: $app-color-red;
  }
  
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 14px;
    color: #000;
  }

  .field-input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px#000000;
    color: #000;
    font-size: 18px;
    outline: none;
    font-weight: 300;
    min-height: 32px;
    // padding-bottom: 12px;

    &:focus {
      border-bottom: solid 2px#000000;
    }
  }
}

.number-field-error {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 10px;
    color: #c2112e;
  }
  span {
    color: #c2112e;
  }

  .field-input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px#C2112E;
    color: #c2112e;
    font-size: 18px;
    outline: none;
    min-height: 32px;
  }
}

.number-field-disabled {
  ul {
    margin-bottom: 24px;
    padding: 0;
    li {
      list-style: none;
    }
  }

  label {
    font-size: 10px;
    color: $app-color-grey4;
  }

  .field-input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px $app-color-grey4;
    color: $app-color-grey4;
    font-size: 18px;
    outline: none;
  }
}
