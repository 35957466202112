@import "../../styles/base/mixins";
@import "../../styles/base/variables";

.field-input {
  max-width: 100% !important;
}

.overview {
  &__form-wrap {
    padding: 2rem;

    ul {
      margin-bottom: 5rem;
      // width: 30rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__broker-details {
    margin-bottom: 5rem;

    &-input {
      input {
        border: none;
        border-bottom: 0.1rem solid $app-color-grey;
        border-radius: 0;
        background-color: transparent;

        &:focus {
          outline: none;
          box-shadow: none !important;
        }

        &.form-control {
          font-size: 1.6rem;
          &.focus {
            border-bottom: 2px solid $app-color-grey !important;
          }
          &.is-valid {
            border-bottom: 0.1rem solid $app-color-grey;
          }
        }

        &.form-control:disabled {
          background-color: transparent;
          color: $app-color-grey;
        }
      }

      .dropdown-menu {
        &.show {
          min-width: 240px;
          left: auto !important;
          right: auto !important;
          padding: 0;
          border: 0;
          box-shadow: 0 1px 5px 5px rgba(136, 147, 164, 0.19);

          .dropdown-item {
            padding: 15px 30px;
            border-bottom: 1px solid rgba(136, 147, 164, 0.19);
            font-size: 1.2rem;

            &:focus {
              outline: none;
            }

            &.active {
              background-color: $app-color-red4;
            }
          }
        }
      }
    }
  }

  &__broker-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.22px;
  }

  &__proposal {
    &-info {
      label {
        color: $app-color-grey4;
      }

      input {
        border-color: $app-color-grey4;
        color: $app-color-grey4;
      }
    }

    &-wrap {
      display: none;

      &--active {
        display: block;
      }
    }
  }
}

.broker-builder {
  left: 50%;
  text-align: center;

  &__wrap {
    width: 100%;
    margin: 0;
    margin-right: 3rem;

    text-align: left;
    display: inline-block;
  }

  &__container {
    box-shadow: $box-shadow2;
    background-color: $app-color-white;
    margin: 3rem;
    padding: 4rem;
  }

  &__form {
    //display: flex;
    flex-direction: row;
    min-width: 1200px;
    display: inline-block;
  }

  &__top-bar {
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
    text-align: center;

    .buttons {
      margin-left: auto;
      margin-right: 1rem;
      margin-top: 2rem;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
      }
    }
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 2rem;
    box-shadow: $box-shadow2;
    margin-top: 3rem;

    .card-values {
      color: $app-color-grey2;
    }

    .card-row {
      padding: 1rem;
    }

    .card-separator {
      background-color: $app-color-grey8;
      min-height: 2px;
    }

    .card-totals {
      font-weight: bold;
    }
  }

  &__card-title {
    color: $app-color-white;
    background-color: $app-color-grey2;
    padding: 24px 20px;
  }

  &__radio_wrap {
    position: relative;
    top: -2rem;
    color: $app-color-blue3;
    font-size: 1.2rem;

    label {
      input[type="radio"] {
        font-size: 1.2rem;
        min-width: 1rem;
        min-height: 1rem;
        cursor: pointer;
      }
    }
  }
}
