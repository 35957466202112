@import "../styles/base/variables";

.app-container {
    max-width: 144rem;
    display: flex;
    flex-direction: row;
}

.main-container {
    margin-left: 19rem;
    width: calc(100% - 190px);
    background-color: $app-color-grey-5;
    min-height: 100vh;
    padding: 2rem;
}