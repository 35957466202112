@import "../../styles/base/variables";

.stepper {
    &__controls {
        width: 90%;
        margin: 0 auto;
        text-align: right;
    }

    &__step {
        font-size: 1.85rem;
        color: $app-color-red;
        
        &__active {
            color: $app-color-red;
            font-size: 1.85rem;
        }
    }
}