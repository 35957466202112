.disLogo{
    max-height: 60px;
}

.main-header{
    padding-top: 10px;
}

.header-widget{
    line-height: 1.7em;

    .title {
        font-size: 18px;
        color: #c4112f!important;
    }

    a#phone-number{
        font-weight: 700;
        color: #0dab8e!important;
        border: 2px solid #0dab8e;
        padding: 10px 30px;
        border-radius: 30px;
        font-size: 20px;
        display: block;
        float: right;
    }

    a#email {
        font-weight: 700;
        border: 2px solid #f58c1f;
        padding: 10px;
        border-radius: 30px;
        font-size: 18px;
        background: #f58c1f;
        display: block;
        color: white;
    }

    .textwidget {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: 0 0;
        font-size: 100%;
        vertical-align: baseline;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-align: center;
    }

    .custom-html-tile {
        text-align: left;
    }
}

.header-bottom{
    background-color: #c4112f;

    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 40px;
    color: #fff;
    font-size: 2em;
    text-decoration: none;

    .header-info{
        float: left;
        padding-bottom: 10px;
        color: #fff;
        text-align: left;
    }

    .privacy-policy{
        text-align: right;
    }

    .privacy-policy a{
        color: #fff;
    }

    .header-info a{
        color: #fff;
    }
}